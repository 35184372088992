// strona koszyka

import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { reduxActions, useDispatch } from 'store';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { ExclamationCircle, ChevronLeft } from 'react-bootstrap-icons';
import Popover from '@mui/material/Popover';
import debounce from 'lodash/debounce';

import { useAppNavigate, useNotifications, useRWD, usePrevious } from 'hooks';
import {
  useGetCart,
  useGetCartsAll,
  useGetCartPaymentsExpired,
  useGetCartPositions,
  usePostCartClear,
  usePutCartImport,
  usePostCartValidate,
  useGetCartImportTemplate,
  useGetCartExport
} from 'api';
import { ICartPositionListItem } from 'api/types';
import { Alert, Breadcrumbs, Container, Link, Loader } from 'components/controls';
import { CartPositions, CartSummary } from 'components/containers';
import { ActionBar } from './components';
import { Button } from 'components/controls';

import styles from 'theme/pages/Cart/Cart.module.scss';
import { MiniCartIcon, CreditCard, Download, ExportIcon } from 'assets/icons';

const Cart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useAppNavigate();
  const { showErrorMessage } = useNotifications();
  const { isMobile } = useRWD();

  // ref popover'a
  const wrapperRef = useRef<HTMLDivElement>(null);

  // ID aktualnego koszyka z url'a
  const { id } = useParams();
  // przekształcenie w numer
  const cartId = useMemo(() => parseInt(id || ''), [id]);

  // fraza wyszukiwania
  const [searchQuery, setSearchQuery] = useState('');

  // lista pozycji do usunięcia
  const [itemsToRemove, setItemsToRemove] = useState<ICartPositionListItem[]>([]);

  // lista zaznaczonyxh pozycji (ID'ki)
  const [checkedItemIds, setCheckedItemIds] = useState<number[]>([]);

  // poprzednia wartość id koszyka
  const prevCartId = usePrevious(cartId);

  // parametry zapytania API
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    searchKeyword: '',
    sort_method: ''
  });

  // czy jest widoczny popover dla importuj
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(null);

  // pobranie informacji o przedawnionych płatności - potrzebne do wyświetlenia alertu
  const { data: paymentsExpiredData } = useGetCartPaymentsExpired(cartId);

  const { refetch: downloadCartTemplate } = useGetCartImportTemplate({
    onSuccess: (data) => {
      const a = document.createElement('a');
      a.download = '';
      a.href = data.url_to_template;
      a.click();
    },
    enabled: false
  });

  // pobranie listy koszyków
  const {
    data: cartsData,
    refetch: refetchCartsData,
    isLoading: isCartLoading
  } = useGetCartsAll({
    enabled: false,
    onSuccess: (data) => {
      const currentCartItem = data.items.find((item) => item.id === cartId);
      const firstCartId = data?.items[0]?.id;
      const secondCartId = data?.items[0]?.id;
      currentCartItem
        ? refetchCartData()
        : navigate(
            firstCartId && firstCartId !== cartId
              ? `/cart/${firstCartId}`
              : secondCartId && secondCartId !== cartId
              ? `/cart/${secondCartId}`
              : '/'
          );
    }
  });

  // pobranie listy pozycji koszyka
  const {
    data: cartPositionsData,
    refetch: refetchCartPositions,
    isLoading: isCartPositionsLoading,
    isRefetching: isCartPositionsRefetching
  } = useGetCartPositions(cartId, queryParams, {
    enabled: false,
    keepPreviousData: true
  });

  // odświeżanie szczegółów koszyka
  const { refetch: refetchCartData } = useGetCart(cartId, {
    enabled: false
  });

  // czyszczenie koszyka
  const { mutate: clearCartPositions } = usePostCartClear(cartId, {
    onSuccess: (response) => {
      navigate(`/cart/${response.data.id}`);
      refetchCartsData();
    }
  });

  // walidacja koszyka
  const { mutate: validateCart, isLoading: isPostCartValidating } = usePostCartValidate(
    cartId || 0,
    {
      onSuccess: () => {
        navigate(`/checkout/${cartId}?checkoutStep=ADDRESS`);
      },
      onError: (data) => {
        if (!data.status) {
          refetchCartsData();
          refetchCartData();
          refetchCartPositions();
        }
      }
    }
  );

  const { mutate: importToCartImport, isLoading: importToCartLoading } = usePutCartImport(
    cartId || 0,
    {
      onSuccess: () => {
        refetchCartsData();
        refetchCartData();
        refetchCartPositions();
        setPopoverAnchor(null);
      }
    }
  );

  const { refetch: exportCart } = useGetCartExport(
    cartId,
    { export_type: 'csv' },
    {
      enabled: false,
      onSuccess: (data) => {
        const a = document.createElement('a');
        a.download = data.file_name;
        a.href = `data:application/csv;base64,${data.content}`;
        a.click();
      }
    }
  );

  // pobieranie początkowych pozycji koszyka
  useEffect(() => {
    if (cartId && prevCartId !== undefined) {
      refetchCartPositions();
    }
  }, [cartId, queryParams.sort_method, queryParams.page]);

  // Ustawienie breadcrumbs'ów (przy renderowaniu strony)
  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs([
        {
          name: t('Koszyk'),
          path: undefined
        }
      ])
    );
  }, []);

  const currentCart = useMemo(
    () => cartsData?.items.find((item) => item.id === cartId),
    [cartsData, cartId]
  );

  // funcja aktualizująca frazę wyszukiwania (sekunda opóźnienia dla optymalizacji ilości zapytań do api)
  const searchQueryDebounce = useCallback(
    debounce(() => refetchCartPositions(), 500),
    []
  );

  // nasłuchiwanie na zmiany frazy wyszukiwania w lokalnym inpucie
  useEffect(() => {
    if (searchQuery) {
      searchQueryDebounce();
    }
  }, [searchQuery]);

  const clearCart = () => {
    if (cartPositionsData) {
      clearCartPositions();
    }
  };

  const handleButtonClick = () => {
    if ((currentCart?.products_count || 0) === 0) {
      showErrorMessage(t('Koszyk jest pusty'));
      return;
    }

    validateCart({ verification_scope: 'positions|cartsPricesUpdate' });
  };

  const createBase64File = (file: Blob): Promise<string | undefined> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString().replace(/^data:(.*,)?/, ''));
      reader.onerror = (error) => reject(error);
    });

  const handleImport = async (files: FileList | null) => {
    const file = files?.[0];
    if (file) {
      const base64 = await createBase64File(file);

      importToCartImport({
        cart_id: cartId,
        file: base64 as string,
        name: files[0].name
      });

      const fileSelector = document.getElementById('importCart') as HTMLInputElement;

      if (fileSelector) {
        fileSelector.value = '';
      }
    }
  };

  return (
    <div className={classnames(styles.componentWrapper, 'StylePath-Pages-Cart')}>
      <Container fullWidth>
        <div className={styles.header}>
          <Breadcrumbs fullWidth />
          <div className={styles.paymentsExpiredData}>
            {paymentsExpiredData?.message && paymentsExpiredData.expired_payments_count > 0 && (
              <Alert type="error" icon={<CreditCard />}>
                <span>{paymentsExpiredData.message}</span>
                <Link to="/dashboard/documents" className={styles.link}>
                  <Trans>Przejdź do zakładki</Trans> <ChevronLeft className={styles.nextIcon} />
                </Link>
              </Alert>
            )}
          </div>
        </div>

        <div className={styles.heading}>
          <h1>
            <Trans>Twój koszyk</Trans>
          </h1>
          <div
            ref={wrapperRef}
            className={classnames(styles.link, styles.importWrapper, {
              [styles.active]: popoverAnchor
            })}>
            <button>
              <MiniCartIcon />
              <span>
                <label>
                  <Trans>Importuj</Trans>
                  <input
                    id="importCart"
                    type="file"
                    onChange={(e) => handleImport(e.target.files)}
                  />
                </label>
              </span>
              <ExclamationCircle
                title={t('Pobierz szablon pliku')}
                onClick={() => setPopoverAnchor(wrapperRef.current)}
              />
            </button>
            <div className={styles.buttonWrapper}>
              <div
                className={classnames(styles.link, styles.exportWrapper, {
                  [styles.active]: popoverAnchor
                })}>
                <button onClick={() => exportCart()}>
                  <ExportIcon />
                  <span>
                    <Trans>Eksportuj</Trans>
                  </span>
                </button>
              </div>
            </div>

            <Popover
              open={!!popoverAnchor && !isMobile}
              anchorEl={popoverAnchor}
              onClose={() => setPopoverAnchor(null)}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}>
              <label className={styles.importWrapper} onClick={() => downloadCartTemplate()}>
                <span>
                  <Trans>Pobierz szablon pliku</Trans>
                </span>
                <Download />
              </label>
            </Popover>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            {!isMobile && (
              <ActionBar
                cart={currentCart}
                setSearchKeyword={setSearchQuery}
                setItemsToRemove={setItemsToRemove}
                checkedItemIds={checkedItemIds}
                cartPositions={cartPositionsData?.items || []}
              />
            )}
            {isCartLoading || isCartPositionsLoading || importToCartLoading ? (
              <Loader />
            ) : (
              <CartPositions
                cartId={cartId}
                isCart={true}
                itemsToRemove={itemsToRemove}
                setItemsToRemove={setItemsToRemove}
                checkedItemIds={checkedItemIds}
                setCheckedItemIds={setCheckedItemIds}
                cartPositionsData={cartPositionsData}
                refetchCartPositions={refetchCartPositions}
                isCartPositionsRefetching={isCartPositionsRefetching}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                onChange={() => {
                  refetchCartsData();
                }}
                searchKeyword={searchQuery}
                noDataPlaceholder={
                  <div className={styles.notFound}>
                    <h3>
                      <Trans>Brak wyników</Trans>
                    </h3>
                    {searchQuery && (
                      <span>
                        <Trans>Zmień kryteria wyszukiwania</Trans>
                      </span>
                    )}
                  </div>
                }
              />
            )}
          </div>
          {isMobile && !!cartPositionsData?.total_count && (
            <div className={styles.mobileActionsWrapper}>
              <ActionBar
                cart={currentCart}
                setSearchKeyword={setSearchQuery}
                setItemsToRemove={setItemsToRemove}
                checkedItemIds={checkedItemIds}
                cartPositions={cartPositionsData?.items || []}
              />
              <Button ghostSecondary onClick={() => clearCart()}>
                <Trans>Wyczyść koszyk</Trans>
              </Button>
            </div>
          )}
          {!!cartsData?.items[0].products_count && (
            <CartSummary
              cartId={cartId}
              buttonOnClick={handleButtonClick}
              clearCart={clearCart}
              isLoading={isPostCartValidating}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default Cart;

import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ChevronDown } from 'react-bootstrap-icons';

import { useAppNavigate, useRWD } from 'hooks';
import { useGetAuthOverloginUsers, usePostOverLogin, usePostOverLogout } from 'api';
import { IOverloginUserListItem } from 'api/types';
import { useDispatch, reduxActions, useSelector } from 'store';
import { Modal, SearchInput, Button } from 'components/controls';
import Table, { IColumn } from 'components/controls/Table';

import styles from 'theme/components/layouts/MainLayout/components/HeaderTopBar/HeaderTopBar.module.scss';

const OverLoginUsers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { profile, overloginUserId } = useSelector((state) => state.auth);
  const navigate = useAppNavigate();
  const { pathname } = useLocation();

  const { isMobile } = useRWD();

  // parametry zapytania o listę użytkowników
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    searchKeyword: ''
  });

  // czy widoczny jest modal z przelogowaniem
  const [isOverLoginModal, setIsOverLoginModal] = useState(false);

  // pobranie listy użytkowników możliwych do przelogownania
  const { data: overloginUsersData, refetch: refetchOverloginUsers } = useGetAuthOverloginUsers(
    queryParams,
    { enabled: false, keepPreviousData: true }
  );

  const { mutateAsync } = usePostOverLogout();

  // przelogowanie na wybranego użytkownika
  const { mutateAsync: postOverLogin } = usePostOverLogin();

  useEffect(() => {
    if (isOverLoginModal) {
      document.addEventListener('keydown', (e) => keyPress(e));
    } else {
      document.removeEventListener('keydown', (e) => keyPress(e));
    }

    return () => {
      document.removeEventListener('keydown', (e) => keyPress(e));
    };
  }, [isOverLoginModal]);

  const keyPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && isOverLoginModal) {
      setIsOverLoginModal(false);
    }
  };

  useEffect(() => {
    if (profile?.role !== 'ROLE_OPEN_PROFILE') {
      refetchOverloginUsers();
    }
  }, [queryParams]);

  const handleOverLogin = async (id: number) => {
    await postOverLogin({ to_switch_user_id: id });

    if (pathname.includes('/cart/')) {
      navigate('/cart/0');
    }

    dispatch(reduxActions.setCurrentCartId(null));
    dispatch(reduxActions.setOverlogin(id));
    setIsOverLoginModal(false);
  };

  // wylogowanie uźytkownika z systemu
  const handleLogout = async () => {
    if (overloginUserId) {
      await mutateAsync();

      if (pathname.includes('/cart/')) {
        navigate('/cart/0');
      }

      dispatch(reduxActions.setCurrentCartId(null));
      dispatch(reduxActions.setOverlogin(null));

      return;
    }
    dispatch(reduxActions.signOut());
  };

  const columns: IColumn<IOverloginUserListItem>[] = useMemo(
    () =>
      isMobile
        ? [
            {
              title: <Trans>Email</Trans>,
              key: 'actions',
              align: 'left',
              renderCell: (item) =>
                item.users_list.map((user) => <div key={user.id}>{user.login}</div>)
            },
            {
              title: <Trans>Nazwa</Trans>,
              key: 'actions',
              align: 'left',
              renderCell: (item) => {
                return (
                  <div>
                    <div>{item.name}</div>
                    {item.users_list.map((user) => (
                      <div className={styles.userListWrapper} key={user.id}>
                        <button className={styles.link} onClick={() => handleOverLogin(user.id)}>
                          {user.name}
                        </button>
                      </div>
                    ))}
                  </div>
                );
              }
            }
          ]
        : [
            {
              title: <Trans>Login</Trans>,
              key: 'actions',
              align: 'left',
              renderCell: (item) =>
                item.users_list.map((user) => <div key={user.id}>{user.login}</div>)
            },
            {
              title: <Trans>Nazwa</Trans>,
              dataIndex: 'name',
              align: 'left'
            },
            {
              title: <Trans>Użytkownik</Trans>,
              key: 'actions',
              align: 'left',
              renderCell: (item) =>
                item.users_list.map((user) => (
                  <div className={styles.userListWrapper} key={user.id}>
                    <button className={styles.link} onClick={() => handleOverLogin(user.id)}>
                      {user.name}
                    </button>
                  </div>
                ))
            }
          ],
    [overloginUsersData?.items]
  );

  if (!overloginUsersData?.items.length && !queryParams.searchKeyword && !isMobile) {
    return null;
  }

  if (isMobile && overloginUserId) {
    return (
      <div className={styles.overloginWrapper}>
        <button className={styles.overloginButton} onClick={() => handleLogout()}>
          <Trans>Wróć na konto</Trans>
          <ChevronDown />
        </button>
      </div>
    );
  }

  return (
    <div className={styles.overloginWrapper}>
      <button className={styles.overloginButton} onClick={() => setIsOverLoginModal(true)}>
        <Trans>Zaloguj jako</Trans>
        <ChevronDown />
      </button>
      {isOverLoginModal && (
        <Modal
          title={t('Zaloguj jako')}
          disableMaxHeight
          isOverLogin
          {...(isMobile ? { fullScreen: true } : {})}
          onClose={() => setIsOverLoginModal(false)}>
          <div className={styles.filtersWrapper}>
            <div className={styles.selectWrapper}>
              <SearchInput
                placeholder={`${t('Szukaj')}...`}
                value={queryParams.searchKeyword}
                onChange={(value) =>
                  setQueryParams(() => ({ searchKeyword: value, page: 1, limit: 10 }))
                }
              />
            </div>
          </div>
          <div className={styles.overLoginTableWrapper}>
            <Table<IOverloginUserListItem>
              columns={columns}
              dataSource={overloginUsersData?.items || []}
              isFullTableOnMobile
              rowKey="id"
              pagination={{
                page: queryParams.page || 1,
                pagesCount: overloginUsersData?.total_pages || 1,
                onChange: (page) => setQueryParams((prevState) => ({ ...prevState, page }))
              }}
            />
          </div>
          <div className={styles.cancelWrapper}>
            <Button ghost onClick={() => setIsOverLoginModal(false)}>
              <Trans>Anuluj</Trans>
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default OverLoginUsers;

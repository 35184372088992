// galeria zdjęć

import React, { FC, useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import Slider, { CustomArrowProps } from 'react-slick';
import slice from 'lodash/slice';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Video from 'yet-another-react-lightbox/plugins/video';

import { useRWD } from 'hooks';
import { IImage, IProductFile, IProductLabel } from 'api/types';
import { ChevronIcon } from 'assets/icons';

import { Label } from 'components/controls';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'yet-another-react-lightbox/styles.css';
import styles from 'theme/pages/Product/components/DoubleGallery/DoubleGallery.module.scss';

// typ danych wejściowych
interface IProps {
  images: IImage[];
  files: IProductFile[];
  labels: IProductLabel[];
}

const DoubleGallery: FC<IProps> = ({ images, files, labels }) => {
  const { isMobile } = useRWD();

  // czy jest otwarta dynamiczna galeria lightbox
  const [open, setOpen] = useState(false);

  // referencja głównego slidera
  const horizontalSliderRef = useRef<Slider>(null);

  // referencja bocznego slidera
  const verticalSliderRef = useRef<Slider>(null);

  // index aktualnego zdjęcia (z tablicy zdjęć)
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // index aktualnego zdjęcia po kliknięciu (z tablicy zdjęć)
  const [index, setIndex] = useState(0);

  useEffect(() => {
    horizontalSliderRef.current?.slickGoTo(currentImageIndex);
    verticalSliderRef.current?.slickGoTo(currentImageIndex);
  }, [currentImageIndex]);

  const PrevArrow = ({ ...props }: CustomArrowProps) => (
    <button
      {...props}
      className={classnames('slick-next slick-arrow', styles.arrow, styles.arrowPrev)}>
      <ChevronIcon />
    </button>
  );

  const NextArrow = ({ ...props }: CustomArrowProps) => (
    <button
      {...props}
      className={classnames('slick-next slick-arrow', styles.arrow, styles.arrowNext)}>
      <ChevronIcon />
    </button>
  );

  const slides = [
    ...images.map((image) => ({
      big: image.big,
      min: image.min,
      source: image.source,
      thumb: image.thumb,
      url: '',
      extension: '',
      thumbnail_url: ''
    })),
    ...files.map((image) => ({
      big: '',
      min: '',
      source: '',
      thumb: '',
      url: image.url,
      extension: image.extension,
      thumbnail_url: images[0].thumb
    }))
  ];

  const sliderVerticalSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: slides.length < 3 ? slides.length : 3,
    slidesToScroll: 1,
    vertical: !isMobile,
    verticalSwiping: !isMobile,
    swipeToSlide: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  };

  const sliderHorizontalSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipeToSlide: true
  };

  const sliderMobileSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  };

  const handleSetOpen = (index: number) => {
    setIndex(index);
    setOpen(true);
  };

  const lightBoxSlides = slides.map((image) => {
    if (image.extension) {
      return {
        type: 'video' as 'image', //typ jako image, ponieważ jest błąd w typowaniu w module yet-another-react-lightbox
        src: image.url,
        sources: [
          {
            src: image.url,
            type: `video/${image.extension}`
          }
        ]
      };
    }

    return {
      src: image.source
    };
  });

  const renderSlider = () => {
    if (isMobile) {
      return (
        <div className={styles.mobileSlider}>
          <Slider
            ref={horizontalSliderRef}
            {...sliderMobileSettings}
            afterChange={(index) => setCurrentImageIndex(index)}>
            {slides.map((image, index) => (
              <div key={index} className={styles.gallerySlide}>
                <img src={image.big} onClick={() => handleSetOpen(index)} />
              </div>
            ))}
          </Slider>
        </div>
      );
    }

    return (
      <>
        <div className={styles.horizontalSlider}>
          {slides.length === 1 ? (
            <div>
              {slides.map((image, index) => (
                <img key={index} src={image.big} onClick={() => handleSetOpen(index)} />
              ))}
            </div>
          ) : (
            <Slider
              ref={horizontalSliderRef}
              {...sliderHorizontalSettings}
              afterChange={(index) => setCurrentImageIndex(index)}>
              {slides.map((image, index) => (
                <div key={index} className={styles.gallerySlide}>
                  <img src={image.big} onClick={() => handleSetOpen(index)} />
                </div>
              ))}
            </Slider>
          )}
        </div>
        <div className={styles.verticalSlider}>
          {slides.length !== 1 && (
            <Slider
              ref={verticalSliderRef}
              {...sliderVerticalSettings}
              afterChange={(index) => setCurrentImageIndex(index)}>
              {[...slides].map((image, index) => (
                <div key={index} onClick={() => setCurrentImageIndex(index)}>
                  <div
                    style={{ backgroundImage: `url('${image.thumb}')`, width: 96, height: 152 }}
                    className={classnames(styles.thumb, {
                      [styles.active]: index === currentImageIndex
                    })}
                  />
                </div>
              ))}
            </Slider>
          )}
        </div>
      </>
    );
  };

  return (
    <div
      className={classnames(styles.wrapperComponent, 'StylePath-Pages-Product-components-Gallery')}>
      {labels.length > 0 && isMobile && (
        <div className={styles.labels}>
          {labels.map((label) => (
            <Label key={label.type} label={label} />
          ))}
        </div>
      )}
      {renderSlider()}

      <Lightbox
        open={open}
        plugins={[Zoom, Video]}
        zoom={{ maxZoomPixelRatio: 1, scrollToZoom: true }}
        close={() => setOpen(false)}
        slides={[...slice(lightBoxSlides, index), ...slice(lightBoxSlides, 0, index)]}
        render={
          lightBoxSlides.length < 2
            ? {
                buttonPrev: () => null,
                buttonNext: () => null
              }
            : {}
        }
      />
    </div>
  );
};

export default DoubleGallery;

// formularz zmiany hasła

import React, { FC } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { reduxActions, useDispatch } from 'store';
import { useGetUserProfile, usePostUserChangePassword } from 'api';
import { transformApiErrorsToFormik } from 'api/helpers';
import { Input, Button } from 'components/controls';

import styles from 'theme/components/containers/ChangePasswordForm/ChangePasswordForm.module.scss';

interface IProps {
  onCancel?: () => void;
  vertical?: boolean;
}

const ChangePasswordForm: FC<IProps> = ({ onCancel, vertical }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // pobranie danych profilowych
  const { refetch: refetchProfile } = useGetUserProfile({
    enabled: false,
    onSuccess: (data) => {
      dispatch(reduxActions.setProfile(data));
    }
  });

  // akcja zmiany hasła
  const { mutate: changePassword, isLoading: isChanging } = usePostUserChangePassword({
    onSuccess: () => {
      refetchProfile();
      onCancel?.();
    },
    onError: (error) => {
      setErrors(transformApiErrorsToFormik(error.error_fields));
    }
  });

  const { values, errors, setFieldValue, handleSubmit, setErrors, resetForm } = useFormik({
    initialValues: {
      last_password: '',
      new_password: '',
      repeat_new_password: ''
    },
    validationSchema: Yup.object().shape({
      last_password: Yup.string().required(t('Pole wymagane')),
      new_password: Yup.string().required(t('Pole wymagane')),
      repeat_new_password: Yup.string()
        .required(t('Pole wymagane'))
        .oneOf([Yup.ref('new_password')], t('Proszę potwierdzić nowe hasło'))
    }),
    onSubmit: (values) => {
      changePassword(values);
      resetForm();
    },
    validateOnChange: false
  });

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        [vertical && styles.vertical],
        'StylePath-Components-Containers-ChangePasswordForm'
      )}>
      <div className={styles.formElement}>
        <label>
          <Trans>Stare hasło</Trans>
        </label>
        <Input
          type="password"
          value={values.last_password}
          onChange={(value) => setFieldValue('last_password', value)}
          error={errors.last_password}
          placeholder="•••••"
        />
      </div>

      <div className={styles.formElement}>
        <label>
          <Trans>Nowe hasło</Trans>
        </label>
        <Input
          type="password"
          value={values.new_password}
          onChange={(value) => setFieldValue('new_password', value)}
          error={errors.new_password}
          placeholder={t('wprowadź')}
        />
      </div>

      <div className={styles.formElement}>
        <label>
          <Trans>Potwierdź nowe hasło</Trans>
        </label>
        <Input
          type="password"
          value={values.repeat_new_password}
          onChange={(value) => setFieldValue('repeat_new_password', value)}
          error={errors.repeat_new_password}
          placeholder={t('wprowadź')}
        />
      </div>

      <div className={styles.actions}>
        {!!onCancel && (
          <Button onClick={onCancel} color="secondary" ghost>
            <Trans>Anuluj</Trans>
          </Button>
        )}
        <Button loading={isChanging} onClick={() => handleSubmit()}>
          <Trans>Zapisz hasło</Trans>
        </Button>
      </div>
    </div>
  );
};

export default ChangePasswordForm;

// Strona pojedynczego produktu

import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useLocation, Link } from 'react-router-dom';
import classnames from 'classnames';
import { Grid } from '@mui/material';

import { useGetProduct, useGetProductBreadcrumbs } from 'api';
import { IPosition, IProductsBreadcrumbsRequest } from 'api/types';
import { reduxActions, useDispatch, useSelector } from 'store';
import { Container, Breadcrumbs } from 'components/controls';
import { AddToShoppingListButton } from 'components/containers';
import { Details, Gallery, DoubleGallery, OthersAlsoBought, SizingTable } from './components';
import { useRWD } from 'hooks';

import 'pure-react-carousel/dist/react-carousel.es.css';
import styles from 'theme/pages/Product/Product.module.scss';

const Product = ({ id }: { id?: string }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.auth);
  const { isMobile } = useRWD();

  // ID produktu (przekształcony na int)
  const { id: paramId } = useParams();
  const productId = useMemo(() => parseInt(paramId || id || ''), [id]);

  // ID kategorii lub fraza wyszukiwania potrzebne jako param w breadcrumbs
  const { state } = useLocation();
  const { categoryId, searchKeywords, mode } = (state || {}) as IProductsBreadcrumbsRequest;

  // Ustawianie danych do dodania do koszyka
  const [positions, setPositions] = useState<IPosition[]>([]);

  // Pobranie produktu
  const { data: productData, isLoading: isProductLoading } = useGetProduct(productId, {
    enabled: !!productId
  });

  // Pobranie listy breadcrumbs'ów danego produktu
  const { data: productBreadcrumbsData } = useGetProductBreadcrumbs(
    productId,
    {
      ...(categoryId ? { categoryId } : {}),
      ...(searchKeywords ? { searchKeywords } : {}),
      ...(mode ? { mode } : {})
    },
    {
      enabled: !!productId
    }
  );

  // funkcja skrolująca stronę do góry
  const scrollToTop = useCallback(
    () => document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
    []
  );

  // aktualna jednostka
  const unit = useMemo(
    () => productData?.units.find((unit) => unit.unit_id === productData.units[0]?.unit_id),
    [productData]
  );

  // scrollowanie do góry strony
  useEffect(() => {
    scrollToTop();
  }, []);

  // ustawienie id produktu w localstorage
  useEffect(() => {
    localStorage.setItem('PRODUCT_ID', id || '');
  }, []);

  // Ustawienie breadcrums'ów do wyświetlenia JCZ: w jakim momencie ? Po załadowaniu danych ?
  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs(
        productBreadcrumbsData
          ? productBreadcrumbsData.items.map((item) => ({
              name: item.name,
              path: item.category_id
                ? `/products?category_id=${item.category_id}&search_keyword=`
                : mode
                ? `/products?mode=${mode}`
                : undefined
            }))
          : [
              {
                name: 'Produkty',
                path: '/products'
              },
              {
                name: productData?.title || 'Produkt'
              }
            ]
      )
    );
  }, [productData, productBreadcrumbsData]);

  // funkcja renderująca przycisk dodawania produktu do listy zakupowej
  const renderAddToShoppingListButton = useCallback(
    () => (
      <div className={classnames(styles.shoppingListButtonWrapper)}>
        {profile?.role !== 'ROLE_OPEN_PROFILE' && productData && isMobile && (
          <AddToShoppingListButton
            product={productData}
            unit={unit}
            quantity={1}
            isShoppingList={false}
          />
        )}
      </div>
    ),
    [profile]
  );

  const offerType = () => {
    if (productData?.colors.length && productData?.colors.length >= 0) {
      return 'https://schema.org/AggregateOffer';
    }

    return 'https://schema.org/Offer';
  };

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Product')}>
      {productData && (
        <Helmet>
          <title>{productData.title} - VMP</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      )}

      <Breadcrumbs isLoading={isProductLoading} fullWidth />
      <>
        <div
          className={styles.productDetailsWrapper}
          itemScope
          itemType="http://schema.org/Product">
          <Container fullWidth>
            {isMobile && (
              <div>
                <div className={styles.producer}>
                  <Link
                    to={`/products?filter_attributes=PRODUCER=${productData?.producer_id}`}
                    itemScope
                    itemProp="brand"
                    itemType="http://schema.org/Brand">
                    {productData?.producer_name}
                    <meta itemProp="name" content={productData?.producer_name} />
                  </Link>
                </div>
                <h1 itemProp="name" className={styles.title}>
                  {productData?.title}
                </h1>
              </div>
            )}
            <Grid container>
              {/* {renderAddToShoppingListButton()} */}
              <div className={styles.galleryWrapper}>
                {!isMobile ? (
                  <DoubleGallery
                    images={productData?.images || []}
                    files={productData?.files || []}
                    labels={productData?.labels || []}
                  />
                ) : (
                  <Gallery
                    images={productData?.images || []}
                    files={productData?.files || []}
                    labels={productData?.labels || []}
                  />
                )}
              </div>
              <Grid className={styles.detailsWrapper}>
                {productData && (
                  <Details product={productData} labels={productData?.labels || []} />
                )}
              </Grid>
              <meta itemProp="description" content={productData?.description} />
              <span itemScope itemProp="offers" itemType={offerType()}>
                <meta itemProp="name" content={productData?.title} />
                <meta itemProp="price" content="0" />
                <meta itemProp="priceCurrency" content={productData?.currency} />
              </span>
              {profile?.role !== 'ROLE_OPEN_PROFILE' && productData && (
                <Grid xs={12}>
                  <SizingTable
                    product={productData}
                    positions={positions}
                    setPositions={setPositions}
                  />
                </Grid>
              )}
            </Grid>
          </Container>
        </div>

        <div className={styles.othersAlsoBoughtWrapper}>
          <Container>
            <OthersAlsoBought productId={productId} />
          </Container>
        </div>
      </>
    </div>
  );
};

export default Product;

// Hook odpowiedzialny za pobranie listy usług w koszyku

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

// parametry requestu do api
export interface IRequest {
  export_type?: 'pdf' | 'xls' | 'csv';
}

export interface IResponse {
  content: string;
  file_name: string;
}

const getOrdersEan = (id: number, params?: IRequest): Promise<IResponse> =>
  axios.get(`/orders/${id}/ean`, { params });

export const useGetOrdersEan = (
  id: number,
  params?: IRequest,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(['order-ean', id, params], () => getOrdersEan(id, params), options);

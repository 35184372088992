import React from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';

import { Link } from 'components/controls';
import {
  CatalogIcon,
  ContractionIcon,
  DeliveryParcelIcon,
  DocumentAttach,
  IntegrationIcon,
  DownloadDashboardIcon
} from 'assets/icons';

import styles from 'theme/pages/Dashboard/Dashboard.module.scss';

const actionsConfig = [
  {
    id: 1,
    name: <Trans>Integracja</Trans>,
    icon: <IntegrationIcon />,
    link: '/cms/SUBPAGE/INTEGRACJA'
  },
  {
    id: 2,
    name: <Trans>Pliki do pobrania</Trans>,
    icon: <DownloadDashboardIcon />,
    link: '/cms/SUBPAGE/PLIKI_DO_POBRANIA'
  },
  {
    id: 3,
    name: <Trans>Katalogi</Trans>,
    icon: <CatalogIcon />,
    link: '/cms/SUBPAGE/KATALOGI'
  },
  {
    id: 4,
    name: <Trans>Kontraktacje</Trans>,
    icon: <ContractionIcon />,
    link: '/products?mode=contracting '
  },
  {
    id: 5,
    name: <Trans>Dropshipping</Trans>,
    icon: <DeliveryParcelIcon />,
    link: '/cms/SUBPAGE/DROPSHIPPING'
  },
  {
    id: 6,
    name: <Trans>Formularze</Trans>,
    icon: <DocumentAttach />,
    link: '/cms/SUBPAGE/FORMULARZE'
  }
];

const BottomActions = () => {
  return (
    <div
      className={classnames(
        styles.listWrapper,
        styles.bottomActions,
        'StylePath-Pages-Dashboard-Components-BottomActions'
      )}>
      {actionsConfig.map((action) => (
        <Link className={styles.action} key={action.id} to={action.link}>
          {action.icon}
          <span>{action.name}</span>
        </Link>
      ))}
    </div>
  );
};

export default BottomActions;

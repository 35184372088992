// breadcrumbs'y

import React from 'react';
import classnames from 'classnames';
import { House, ChevronRight } from 'react-bootstrap-icons';

import { useSelector } from 'store';
import { useAppNavigate } from 'hooks';
import { Container } from 'components/controls';

import styles from 'theme/components/controls/Breadcrumbs/Breadcrumbs.module.scss';

const Breadcrumbs = ({ isLoading, fullWidth }: { isLoading?: boolean; fullWidth?: boolean }) => {
  const navigate = useAppNavigate();
  const { breadcrumbs } = useSelector((state) => state.ui);

  return (
    <div
      className={classnames(styles.componentWrapper, 'StylePath-Components-Controls-Breadcrumbs')}>
      <Container fullWidth={fullWidth}>
        <ul className={styles.breadcrumbs} itemScope itemType="http://schema.org/BreadcrumbList">
          <House onClick={() => navigate('/')} className={styles.home} />
          <ChevronRight className={styles.arrow} />
          {isLoading ? (
            <li className={classnames(styles.breadcrumb, styles.placeholder)}>
              <span />
            </li>
          ) : (
            breadcrumbs.map(({ name, path }, index) => (
              <li
                key={`${path}-${name}`}
                className={styles.breadcrumb}
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem">
                <meta itemProp="item" content={window.location.href} />
                <span
                  onClick={() => path && navigate(path)}
                  className={classnames(styles.name, {
                    [styles.disabled]: !path && !breadcrumbs.length
                  })}
                  itemProp="name">
                  {name}
                </span>
                {index !== breadcrumbs.length - 1 && <ChevronRight className={styles.arrow} />}
                <meta itemProp="position" content={String(index + 1)} />
              </li>
            ))
          )}
        </ul>
      </Container>
    </div>
  );
};

export default Breadcrumbs;

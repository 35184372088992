// Ruting aplikacji
import React, { lazy, Suspense } from 'react';
import { Route, Routes as RouterRoutes, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

import useRoutes from './useRoutes';
import { DashboardSubLayout, DefaultLayout, MainLayout } from 'components/layouts';
import { Agreements, ChangePasswordForm } from 'components/containers';
import { Modal } from 'components/controls';
import * as Pages from 'pages';

const DownloadExport = lazy(
  () => import(/* webpackMode: "lazy", webpackChunkName: "DownloadExport" */ 'pages/DownloadExport')
);
const Login = lazy(
  () => import(/* webpackMode: "lazy", webpackChunkName: "Login" */ 'pages/Login')
);
const ForgotPassword = lazy(
  () => import(/* webpackMode: "lazy", webpackChunkName: "ForgotPassword" */ 'pages/ForgotPassword')
);
const ResetPassword = lazy(
  () => import(/* webpackMode: "lazy", webpackChunkName: "ResetPassword" */ 'pages/ResetPassword')
);
const Home = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "Home" */ 'pages/Home'));

declare global {
  interface Window {
    gtag: (arg1: string, arg2: string, arg3: object) => void;
  }
}

const Routes = () => {
  const { t } = useTranslation();
  const {
    isAuthenticated,
    redirectUrl,
    urlPrefix,
    profile,
    notificationModal,
    isProfileLoading,
    isOpenProfileReady,
    dismissNotificationModal
  } = useRoutes();

  const deleteCookie = (name: string) => {
    document.cookie = `${name}=;path=/;Max-Age=0;`;
  };

  const handleDeclineCookies = () => {
    const ANALITICS_COOKIES = [
      'smvr',
      '_ga_84032217',
      '_gat',
      '_gid',
      'ccx_uid',
      'smcfds',
      'smvr',
      'smuuid',
      'smwp'
    ];

    ANALITICS_COOKIES.forEach((cookie) => deleteCookie(cookie));
  };

  const renderHelmet = () => (
    <Helmet>
      <html lang={urlPrefix.replace('/', '')} />
    </Helmet>
  );

  // strona z informacją o pobieraniu danych zalogowanego użytkownika
  if (isProfileLoading || isOpenProfileReady) {
    return <div />;
  }

  // wymuszenie zmiany hasła (tymczasowo wyłączona funkcjonalność z powodu braku możliwości zmiany warości pola change_password)
  if (profile?.change_password && profile?.role !== 'ROLE_OPEN_PROFILE') {
    return (
      <DefaultLayout>
        {renderHelmet()}
        <Modal title={t('Ustawienie hasła')}>
          <ChangePasswordForm />
        </Modal>
      </DefaultLayout>
    );
  }

  if (profile?.consents_required && profile?.role !== 'ROLE_OPEN_PROFILE') {
    return (
      <DefaultLayout>
        {renderHelmet()}
        <Modal title="" onClose={() => null}>
          <Agreements userId={profile.id} />
        </Modal>
      </DefaultLayout>
    );
  }

  return (
    <>
      {renderHelmet()}
      <Suspense>
        <RouterRoutes>
          <Route element={isAuthenticated ? <MainLayout /> : <DefaultLayout />}>
            <Route path={`${urlPrefix}/login`} element={<Login />} />
            <Route path={`${urlPrefix}/forgot-password`} element={<ForgotPassword />} />
            <Route path={`${urlPrefix}/reset-password/:token`} element={<ResetPassword />} />
          </Route>
          <Route
            element={isAuthenticated ? <MainLayout /> : <Navigate to={`${urlPrefix}/login`} />}>
            <Route path={`${urlPrefix}`} element={<Home />} />
            <Route path={`${urlPrefix}/download/export/:hash`} element={<DownloadExport />} />
            {profile?.role !== 'ROLE_OPEN_PROFILE' ? (
              <>
                <Route path={`${urlPrefix}/cart/:id`} element={<Pages.Cart />} />
                <Route path={`${urlPrefix}/checkout/:cartId`} element={<Pages.Checkout />} />
                <Route
                  path={`${urlPrefix}/dashboard`}
                  element={<DashboardSubLayout urlPrefix={urlPrefix} />}>
                  <Route index element={<Pages.Dashboard />} />
                  <Route path="carts" element={<Pages.Carts />} />
                  <Route path="carts/import" element={<Pages.CartsImport />} />
                  <Route path="clients" element={<Pages.Clients />} />
                  <Route path="deliveries" element={<Pages.Deliveries />} />
                  <Route path="documents" element={<Pages.Documents />} />
                  <Route path="export" element={<Pages.Export />} />
                  <Route path="faq" element={<Pages.Faq />} />
                  <Route path="message-settings" element={<Pages.MessageSettings />} />
                  <Route path="my-account" element={<Pages.MyAccount />} />
                  <Route path="offers/dedicated" element={<Pages.OffersDedicated />} />
                  <Route path="offers/periodic" element={<Pages.OffersPeriodic />} />
                  <Route path="orders" element={<Pages.Orders />} />
                  <Route path="orders/:orderId" element={<Pages.Order />} />
                  <Route path="payments" element={<Pages.Payments />} />
                  <Route path="finances" element={<Pages.Finances />} />
                  <Route path="receivers" element={<Pages.Receivers />} />
                  <Route path="favourites" element={<Pages.ShoppingLists />} />
                  <Route path="terms" element={<Pages.Terms />} />
                  <Route path="users" element={<Pages.Users />} />
                  <Route path="cms/sections" element={<Pages.CmsSections />} />
                  <Route path="cms/sections/:id" element={<Pages.CmsSection />} />
                  <Route path="cms/articles" element={<Pages.CmsArticles />} />
                  <Route path="cms/articles/:id" element={<Pages.CmsArticle />} />
                  <Route path="cms/articles/create" element={<Pages.CmsArticle />} />
                  <Route path="cms/media" element={<Pages.CmsMedia />} />
                </Route>
              </>
            ) : (
              <Route path={`${urlPrefix}/dashboard/*`} element={<Navigate to="/" replace />} />
            )}
            <Route path={`${urlPrefix}/wip`} element={<Pages.WorkInProgress />} />
            <Route path={`${urlPrefix}/products`} element={<Pages.Products />} />
            <Route path={`${urlPrefix}/products/:id`} element={<Pages.Product />} />
            <Route path={`${urlPrefix}/register`} element={<Pages.Register />} />
            <Route path="confirm_register" element={<Pages.RegisterEmailConfirm />} />
            <Route path={`${urlPrefix}/cms/:sectionId/:articleId`} element={<Pages.CmsPage />} />
            <Route path="payment/success" element={<Pages.PaymentSuccess />} />
            <Route path={`${urlPrefix}/payment/status`} element={<Pages.PaymentStatus />} />
            <Route path="newsletter/confirm" element={<Pages.NewsletterConfirmPage />} />
            <Route path={`${urlPrefix}/*`} element={<Pages.UrlResolver />} />
          </Route>
          <Route path="*" element={<Navigate to={redirectUrl} replace />} />
        </RouterRoutes>

        {notificationModal && (
          <Modal title={notificationModal.title} onClose={dismissNotificationModal}>
            <div dangerouslySetInnerHTML={{ __html: notificationModal.content }} />
          </Modal>
        )}
        <CookieConsent
          location="bottom"
          buttonText={t('Potwierdzam wszystkie')}
          declineButtonText={t('Potwierdzam wymagane')}
          onDecline={handleDeclineCookies}
          onAccept={() => {
            window?.gtag('consent', 'update', {
              ad_storage: 'granted',
              ad_user_data: 'granted',
              ad_personalization: 'granted',
              analytics_storage: 'granted'
            });
          }}
          enableDeclineButton
          overlay
          overlayStyle={{
            zIndex: 999999
          }}
          style={{ background: '#ede8e4', color: '#383a3c', zIndex: 99999 }}
          buttonStyle={{
            background: '#e1a346',
            height: 40,
            padding: '0 32px',
            fontSize: '12px',
            fontWeight: 600,
            color: '#fff'
          }}
          declineButtonStyle={{
            background: '#c71717',
            height: 40,
            padding: '0 32px',
            fontSize: '12px',
            fontWeight: 600,
            color: '#fff'
          }}>
          {t(
            'Wykorzystujemy pliki cookies do prawidłowego działania serwisu, aby analizować ruch w witrynie oraz w celach reklamowych. Współpracujemy w tym celu z zewnętrznymi dostawcami. Klikając &quot;Potwierdzam wszystkie&quot;, wyrażasz zgodę na przechowywanie wszystkich lików cookies na Twoim urządzeniu, natomiast klikając &quot;Potwierdź wymagane&quot; - tylko tych niezbędnych do prawidłowego działania serwisu.'
          ).replaceAll('&quot;', '"')}
        </CookieConsent>
      </Suspense>
    </>
  );
};

export default Routes;

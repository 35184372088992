// sekcja demo na stronie głównej

import React, { FC, useEffect } from 'react';

import { useGetCmsSectionArticle, useGetCmsSectionArticles } from 'api';

import { HtmlBlockMultipleArticle, HtmlBlockSingleArticle } from './components';

import 'theme/pages/Home/Home.scss';

interface IProps {
  sectionId: string;
  articleId?: string;
  withBreadCrumbs?: boolean;
  isCmsPage?: boolean;
}

export const HtmlBlock: FC<IProps> = ({ sectionId, articleId, withBreadCrumbs, isCmsPage }) => {
  // pobranie bloku html
  const { data: singleArticleData, refetch: refetchSingleData } = useGetCmsSectionArticle(
    sectionId,
    articleId || '',
    {
      enabled: false
    }
  );

  // pobranie bloku html
  const { data: multipleArticleData, refetch: refetchMultipleData } = useGetCmsSectionArticles(
    sectionId,
    { fetchArticleFields: true },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (articleId) {
      refetchSingleData();

      return;
    }

    refetchMultipleData();
  }, [articleId, sectionId]);

  if (multipleArticleData || singleArticleData) {
    return (
      <>
        {articleId ? (
          <HtmlBlockSingleArticle
            content={singleArticleData}
            withBreadCrumbs={withBreadCrumbs}
            isCmsPage={isCmsPage}
          />
        ) : (
          <HtmlBlockMultipleArticle content={multipleArticleData} />
        )}
      </>
    );
  }

  return (
    <div className="homePlaceholder">
      <div></div>
      <div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default HtmlBlock;
